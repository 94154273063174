import React, { useState, useEffect, useCallback } from "react";
import Button from "../ui/Button";
import CustomLink from "../ui/CustomLink";
import Tagify from "../ui/Tagify";
import { HiMenuAlt3 } from "react-icons/hi";
import NavLinks from "./NavLinks";

const Navbar = ({ isHome = false }) => {
  // Set show for mobile
  const [show, setShow] = useState(false);
  const toggleNav = () => setShow(!show);

  // state for if nav color should change based on scroll. event listener on window
  const [colorChange, setColorChange] = useState(false);

  const changeNavbarColor = useCallback(() => {
    return window.scrollY >= 10 ? setColorChange(true) : setColorChange(false);
  }, [setColorChange]);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, [changeNavbarColor]);

  // Class for navbar
  const position = "w-full z-50 fixed ";
  const animation = "transition duration-500 ease-in-out ";
  // Show white text on home page desktop nav else black
  const textColor =
    colorChange || !isHome || show ? "text-black " : "lg:text-white ";
  // Set bg to white on scroll
  const bgColor = colorChange || show ? "bg-white " : "transparent ";
  const navClass = position + animation + bgColor + textColor;

  // If its home page or on scroll, btn is magenta else black
  const btnColor = isHome || colorChange ? "magenta" : "black";

  return (
    <nav className={navClass}>
      <div className="nav-container flex flex-col lg:flex-row justify-between px-12 py-6">
        <div className={"flex flex-row w-full justify-between items-center"}>
          <CustomLink to="/">
            <div className="text-4xl flex items-center justify-center cool-sans">
              <Tagify showBlack={!isHome && !colorChange}>
                <span className="hidden lg:flex">CoolStudio</span>
                <span
                  className={
                    "relative lg:hidden " +
                    animation +
                    (isHome && !colorChange && !show ? "text-white" : "")
                  }
                >
                  CS
                </span>
              </Tagify>
            </div>
          </CustomLink>
          <button
            onClick={toggleNav}
            className={
              "flex text-2xl lg:hidden " +
              (isHome ? "text-primaryMagenta" : "text-black")
            }
          >
            <HiMenuAlt3 />
          </button>
        </div>

        <div
          className={
            "flex py-2 flex-col w-full justify-center lg:grid lg:grid-flow-col lg:justify-end lg:items-center " +
            (show ? "flex" : "hidden")
          }
        >
          <NavLinks isHome={isHome} colorChange={colorChange} />

          <div className="mt-2 lg:ml-10 lg:mt-0">
            {/* <Link to="/contact"> */}
              <CustomLink
                className="transition duration-500 ease"
                to="/contact"
              >
                <Button className={" " + animation} color={btnColor} size="nav">
                  Talk to us
                </Button>
              </CustomLink>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
