import React from "react";
import CustomLink from "../ui/CustomLink";
import Tagify from "../ui/Tagify";

const Footer = () => {
  return (
    <footer className="bg-primaryBlack w-full">
      <div className="max-width px-12 py-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 md:gap-3 text-xl text-white">
        <div className="footer-links">
          <ul>
            <li>
              <CustomLink to="/">
                <div className="text-6xl">
                  <Tagify>CS</Tagify>
                </div>
              </CustomLink>
            </li>
          </ul>
        </div>
        <div className="footer-links">
          {/* <ul>
          </ul> */}
        </div>
        <div className="footer-links">
          <ul>
            <li>
              <CustomLink to="/our-approach">Our Approach</CustomLink>
            </li>
            <li>
              <CustomLink to="/our-services">Our Services</CustomLink>
            </li>
            {/* <li>
              <CustomLink to="/projects">Projects</CustomLink>
            </li> */}
            <li>
              <CustomLink to="/about">About</CustomLink>
            </li>
            <li>
              <CustomLink to="/legal">Privacy Policy</CustomLink>
            </li>
            {/* <li>
              <CustomLink to="/blog">Blog</CustomLink>
            </li> */}
          </ul>
        </div>
        <div className="text-primaryBlue footer-links">
          <ul>
            <li className="text-primaryMagenta">
              <CustomLink to="/contact">Talk to us</CustomLink>
            </li>
            <li className="text-primaryBlue">
              <a
                rel="noreferrer"
                href="https://www.linkedin.com/company/coolstudio/"
                target="_blank"
              >
                LinkedIn
              </a>
            </li>
            {/* <li>
              <a
                rel="noreferrer"
                href="https://www.facebook.com/coolstudioltd"
                target="_blank"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="https://twitter.com/coolstudioltd"
                target="_blank"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="https://www.instagram.com/coolstudioltd"
                target="_blank"
              >
                Instagram
              </a>
            </li> */}
          </ul>
        </div>
        <div className="col-span-full footer-links text-gray-600 text-xs">
          <ul>
            <li className="small">
              Cool Studio Limited is a company registered in England and Wales, Company Number 11974828.
            </li>
            <li className="small">
              &copy; All rights reserved | Cool Studio |{" "}
              {new Date().getFullYear()}
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
