import React from "react";
import Button from "../ui/Button";
import moduleColors from "./modules-colors";

const CTA = ({
  title = "Ready to start your next project",
  btnText = "Talk to us",
  to = "/contact",
  color = "gray",
}) => {
  const { bgColor, btnColor } = moduleColors(color);

  return (
    <section className={bgColor}>
      <div className="py-24 px-12 flex flex-col max-width">
        <p className="text-3xl md:text-6xl font-thin mb-5 cool-sans">{title}</p>
        <Button to={to} color={btnColor}>
          {btnText}
        </Button>
      </div>
    </section>
  );
};

export default CTA;
