import React, {Fragment} from "react";
import CustomLink from "../ui/CustomLink";

const NavLinks = ({ isHome, colorChange }) => {
  const animation = "transition duration-500 ease ";

  const navLinks = [
    { text: "Our Approach", to: "/our-approach" },
    { text: "Our Services", to: "/our-services" },
    // { text: "Projects", to: "/projects" },
    { text: "About", to: "/about" },
    // { text: "Blog", to: "/blog" },
  ];

  const linkClass = () => {
    if (isHome) {
      return colorChange ? "text-gray-900" : "lg:text-white";
    } else {
      return "text-gray-900 opacity-50";
    }
  };

  return (
    <Fragment>
      {navLinks.map((item, index) => {
        const { text } = item;
        const { to } = item;
        return (
          <CoolLink key={index} to={to} animation={animation} colorChange={colorChange} linkClass={linkClass}>{text}</CoolLink>
        );
      })}
    </Fragment>
  );
};

export const CoolLink = ({ key, to, animation, colorChange, linkClass, children }) => {
  return (
    <CustomLink
      key={key}
      to={to}
      className={
        "text-lg w-full lg:mx-10 font-light " + animation + linkClass()
      }
      activeClassName={
        "opacity-100 w-full lg:mx-10 " +
        (colorChange ? "text-gray-900" : "")
      }
    >
      {children}
    </CustomLink>
  )
}

export default NavLinks;
