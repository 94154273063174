import React, {Fragment} from "react";

export default function Tagify({ children, showBlack = false }) {
  return (
    <Fragment>
      <span
        className={
          "transition duration-500 ease " +
          (showBlack ? "text-primaryBlack" : "text-primaryMagenta")
        }
      >
        {"<"}
      </span>
      {children}&nbsp;
      <span
        className={
          "transition duration-500 ease " +
          (showBlack ? "text-primaryBlack" : "text-primaryMagenta")
        }
      >
        {"/>"}
      </span>
    </Fragment>
  );
}
