import React, { useCallback } from "react";
import TransitionLink from "gatsby-plugin-transition-link";

const CustomLink = ({ to, children, className, activeClassName, onClick }) => {
  // Page transitions
  const entryTransition = {
    trigger: ({ node }) => {
      entryTransition.entryTrigger(node);
      // window.scroll(0,0)
    },
    entryTrigger: useCallback((container) => {
      container.setAttribute(
        "style",
        "animation: fadeUpIn 0.8s cubic-bezier(0.8, 0, 0.17, 1) forwards;"
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []),
    zIndex: 1,
  };

  const exitTransition = {
    preventScrollJump: true,
    trigger: ({ node }) => {
      exitTransition.exitTrigger(node);
    },
    exitTrigger: useCallback((container) => {
      container.setAttribute(
        "style",
        "animation: fadeUpOut 0.8s cubic-bezier(0.8, 0, 0.17, 1); forwards;"
      );
    }, []),
    length: 0.8,
    zIndex: 2,
  };

  return (
    <TransitionLink
      preventScrollJump={true}
      entry={entryTransition}
      exit={exitTransition}
      className={className}
      activeClassName={activeClassName}
      onClick={onClick}
      to={to}
    >
      {children}
    </TransitionLink>
  );
};

export default CustomLink;
