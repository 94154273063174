const moduleColors = (backgroundColor = "white") => {
  var bgColor = "bg-primaryBlack text-white";
  var btnColor = "magenta";

  // Color of background + text
  if (backgroundColor === "black") {
    bgColor = "bg-primaryBlack text-white";
  } else if (backgroundColor === "magenta") {
    bgColor = "bg-primaryMagenta text-black";
  } else if (backgroundColor === "yellow") {
    bgColor = "bg-primaryYellow text-black";
  } else if (backgroundColor === "blue") {
    bgColor = "bg-primaryBlue text-black";
  } else if (backgroundColor === "gray") {
    bgColor = "bg-gray-100 text-black";
  } else {
    bgColor = "bg-white text-black";
  }

  // button color depending on background color
  backgroundColor === "black" ||
  backgroundColor === "gray" ||
  backgroundColor === "white"
    ? (btnColor = "magenta")
    : (btnColor = "black");

  const iterateTextColor =
    backgroundColor === "black"
      ? [
          "text-primaryMagenta",
          "text-primaryGreen",
          "text-primaryOrange",
          "text-primaryBlue",
          "text-primaryYellow",
        ]
      : ["text-black"];

  const iterateClass = (index) => {
    if (index < iterateTextColor.length) {
      return iterateTextColor[index];
    } else {
      return iterateTextColor[index % iterateTextColor.length];
    }
  };

  return {
    bgColor,
    btnColor,
    iterateClass,
  };
};

export default moduleColors;
