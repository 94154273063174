import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import Navbar from "./Navbar";
import Footer from "./Footer";
import Cta from "../modules/CTA";
// import OurTechnology from "../../content/ourApproach/OurTechnology";

const Layout = ({ children, isHome = false, title, showCTA = true }) => {
  return (
    <Fragment>
      <Helmet
        title={title ? title + " | Cool Studio" : "Cool Studio"}
        description="We empower businesses with code"
      />
      <Navbar isHome={isHome} />
      <main className="min-h-screen">
        {children}
        {showCTA && <Cta style={{ bottom: 0 }} />}
      {/* <OurTechnology /> */}

        <Footer />
      </main>
    </Fragment>
  );
};

export default Layout;
