import React from "react";
import CustomLink from "./CustomLink";

export const Button = ({
  children,
  color = "black",
  size = "normal",
  to = "/contact",
}) => {
  const btnColor = () => {
    if (color === "black") {
      return "bg-primaryBlack ";
    } else if (color === "magenta") {
      return "bg-primaryMagenta ";
    }
  };
  const btnSize = () => {
    if (size === "normal") {
      return "text-lg md:text-2xl ";
    } else if (size === "small") {
      return "text-base md:text-lg ";
    } else if (size === "nav") {
      return "text-sm md:text-xs xl:text-base ";
    }
  };

  return (
      <button
        className={
          "py-3 pl-6 text-white hover:opacity-75 flex flex-row justify-between items-center align-middle w-max " +
          btnSize() +
          btnColor() +
          (!to && "pr-6")
        }
      >
        <span>{children}</span>
        {/* If to is not set to null */}
        {to && <span className="ml-3 cool-sans pt-2 pr-6">{">"}</span>}
      </button>
  );
};

const LinkButton = (props) => {
  return (
    <CustomLink to={props.to} className="w-max">
      <Button {...props} />
    </CustomLink>
  )
}

export default LinkButton;
